import React, { useEffect, useState, useCallback } from 'react';
import { Button, Radio, Select, Slider } from "antd";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import { CloseOutlined } from "@ant-design/icons";
import ColorRangeSelector from "./ColorRangeSelector";

interface SidebarProps {
    colors: any;
    thresholds: any;
    headers: any[];
    defaults: any;
    config: any;
    onSelect: (area: string, feature: string, lag: string, key: string) => void;
    handleZoomChange: any;
    handleCenterChange: any;
    handleColorChange: any;
    handleOpdelingChange: any;
}

// Determine if keys are numerical
function isNumeric(keys: any) {
    return keys.every((key: any) => !isNaN(key));
}

const Sidebar: React.FC<SidebarProps> = ({
     colors,
     thresholds,
     headers,
     defaults,
     config,
     onSelect,
     handleZoomChange,
     handleCenterChange,
     handleColorChange,
     handleOpdelingChange,
 }) => {
    const [isCardVisible, setIsCardVisible] = useState(true);
    const [activeTab, setActiveTab] = useState('indstillinger');

    const [selectedOmraade, setSelectedOmraade] = useState<string>(defaults.omraade);
    const [selectedFeature, setSelectedFeature] = useState<string>(defaults.feature);

    const [selectedOpdeling, setSelectedOpdeling] = useState<number>(10);

    const [selectedLag, setSelectedLag] = useState<string>(defaults.lags.default);
    const [selectedKey, setSelectedKey] = useState<string>(defaults.keys.default);

    const [availableLags, setAvailableLags] = useState<any[]>(defaults.lags.lags);
    const [availableKeys, setSelectedKeys] = useState<any[]>(defaults.keys.keys);

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    // Memoize handleOmraadeFeatureChange to prevent re-rendering issues
    const handleOmraadeFeatureChange = useCallback((value: any) => {

        const [omraade, feature] = value.split('-');

        setSelectedOmraade(omraade);
        setSelectedFeature(feature);

        // Fetch the corresponding omraade data from the headers
        const selectedOmraadeData = headers.find((data: any) => data.omraade === omraade);

        if (selectedOmraadeData) {
            // Find the feature object under the selected omraade
            const selectedFeatureData = selectedOmraadeData.features.find((f: any) => f.feature === feature);

            if (selectedFeatureData) {
                // Set available lags for the selected feature
                setAvailableLags(selectedFeatureData.lags.map((lag: any) => lag.lag)); // Extract lags

                // Find the first lag with keys, if available, and set the keys
                const firstLagWithKeys = selectedFeatureData.lags.find((lag: any) => lag.keys && lag.keys.length > 0);

                if (firstLagWithKeys) {
                    setSelectedKeys(firstLagWithKeys.keys);
                    setSelectedKey(firstLagWithKeys.keys[0]);
                } else {
                    setSelectedKeys([]); // No keys available
                }
            }
        }

        onSelect(omraade, feature, selectedLag, selectedKey)

    }, [headers]);

    // Memoized lag change handler to prevent unnecessary re-renders
    const handleLagChange = useCallback((value: any) => {
        setSelectedLag(value);
        onSelect(selectedOmraade, selectedFeature, value, selectedKey);
    }, [selectedOmraade, selectedFeature, selectedKey, onSelect]);

    // Memoized key slider and dropdown change handlers
    const handleKeySliderChange = useCallback((value: any) => {
        setSelectedKey(value);
        onSelect(selectedOmraade, selectedFeature, selectedLag, value);
    }, [selectedOmraade, selectedFeature, selectedLag, onSelect]);

    const handleKeyDropdownChange = useCallback((value: any) => {
        setSelectedKey(value);
    }, []);

    const selectOpdeling = useCallback((value: any) => {
        handleOpdelingChange(value);
        setSelectedOpdeling(value);
    }, [handleOpdelingChange]);

    return (
        <>
            <div style={{ paddingTop: 54 }}></div>

            <div>
                <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <Button
                            type={activeTab === 'indstillinger' ? 'primary' : 'text'}
                            style={{ flex: 1, marginRight: 2 }}
                            onClick={() => setActiveTab('indstillinger')}
                        >
                            Indstillinger
                        </Button>
                        <Button
                            type={activeTab === 'om_projektet' ? 'default' : 'text'}
                            style={{ flex: 1, marginLeft: 2 }}
                            onClick={() => setActiveTab('om_projektet')}
                        >
                            Om projektet
                        </Button>
                    </div>
                </div>
            </div>

            {activeTab === 'indstillinger' && (
                <div>

                    <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                        <div style={{ padding: 8 }}>
                            <Title level={4} style={{ marginTop: 0 }}>Atlas</Title>
                            <Paragraph style={{ marginTop: 0 }}>
                                Værktøj til at studere udviklingen i Danmark. Vælg område herunder:
                            </Paragraph>

                            <Select
                                style={{ width: '100%' }}
                                placeholder="Vælg et område og funktion"
                                value={`${selectedOmraade}-${selectedFeature}`} // Set default selected value
                                onChange={(value) => handleOmraadeFeatureChange(value)}
                            >
                                {!!headers && headers.map(omraade => (
                                    <Select.OptGroup
                                        label={capitalizeFirstLetter(omraade.omraade)} // Capitalize first letter of "omraade"
                                        key={omraade.omraade}
                                    >
                                        {omraade.features.map((feature: any) => (
                                            <Select.Option
                                                key={`${omraade.omraade}-${feature.feature}`}
                                                value={`${omraade.omraade}-${feature.feature}`}
                                            >
                                                {typeof feature.feature === 'object'
                                                    ? capitalizeFirstLetter(JSON.stringify(feature.feature)) // Capitalize if object
                                                    : capitalizeFirstLetter(feature.feature) // Capitalize first letter of "feature"
                                                }
                                            </Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </div>
                    </div>

                    {selectedFeature && (
                        <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                            <div style={{ padding: 8 }}>
                                <Title level={4} style={{ marginTop: 0 }}>Lag</Title>
                                <Radio.Group
                                    style={{ display: 'flex', width: '100%' }}
                                    value={selectedLag || "auto"}
                                    onChange={(e) => handleLagChange(e.target.value)}
                                >
                                    {/* Always enabled "Auto" */}
                                    <Radio.Button style={{ flex: 1, textAlign: 'center' }} value="auto" key="auto">
                                        Auto
                                    </Radio.Button>

                                    {/* "Kommune" */}
                                    <Radio.Button style={{ flex: 1, textAlign: 'center' }} value="kommune" key="kommune" disabled={!availableLags.includes("kommune")}>
                                        Kommune
                                    </Radio.Button>

                                    {/* "Sogn" */}
                                    <Radio.Button style={{ flex: 1, textAlign: 'center' }} value="sogn" key="sogn" disabled={!availableLags.includes("sogn")}>
                                        Sogn
                                    </Radio.Button>

                                    {/* "Merged" */}
                                    <Radio.Button style={{ flex: 1, textAlign: 'center' }} value="merged" key="merged" disabled={!availableLags.includes("merged")}>
                                        Merged
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                    )}

                    {selectedFeature && selectedLag && (
                        <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                            <div style={{ padding: 8 }}>
                                {isNumeric(availableKeys) ? (
                                    <>
                                        <Slider
                                            min={Math.min(...availableKeys)}
                                            max={Math.max(...availableKeys)}
                                            defaultValue={availableKeys[0]}
                                            onChange={handleKeySliderChange}
                                        />
                                        {/* Display min and max values */}
                                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 8px 0 8px' }}>
                                            <Paragraph>{Math.min(...availableKeys)}</Paragraph>
                                            <Paragraph>{Math.max(...availableKeys)}</Paragraph>
                                        </div>
                                    </>
                                ) : (
                                    <Select
                                        style={{ width: '100%' }}
                                        value={selectedKey}
                                        onChange={handleKeyDropdownChange}
                                    >
                                        {availableKeys.map((key: any) => (
                                            <Select.Option key={key} value={key}>
                                                {key}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                        <div style={{ padding: 8 }}>
                            <Title level={4} style={{ marginTop: 0 }}>Interval</Title>
                            <Slider min={2} max={10} defaultValue={selectedOpdeling} onChange={selectOpdeling} />
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 8px 0 8px' }}>
                                <Paragraph>0</Paragraph>
                                <Paragraph>10</Paragraph>
                            </div>
                        </div>
                    </div>

                    <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                        <div style={{ padding: 8 }}>
                            <ColorRangeSelector parentHandleColorChange={handleColorChange}></ColorRangeSelector>
                        </div>
                    </div>

                </div>
            )}

            {activeTab === 'om_projektet' && (
                <div>
                    <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                        <div style={{ padding: 8 }}>
                            <Title level={4} style={{ marginTop: 0 }}>Atlas</Title>
                            <Paragraph style={{ marginTop: 0 }}>
                                Værktøj til at studere udviklingen i Danmark.
                            </Paragraph>
                        </div>
                    </div>
                </div>
            )}

            {isCardVisible && (
                <div>
                    <div className="floating-card" style={{ opacity: 0.9, height: 100, position: 'fixed', bottom: 20, left: 20, zIndex: 1000, width: 'calc(100% - 40px)' }}>
                        <Button
                            type="text"
                            icon={<CloseOutlined />}
                            onClick={() => setIsCardVisible(false)}
                            style={{
                                position: 'absolute',
                                top: 8,
                                left: 8,
                                zIndex: 1001,
                                opacity: 0.6
                            }}
                        />
                        <div style={{ padding: 8, marginLeft: 36, marginTop: 5 }}>
                            Kortet viser...
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Sidebar;
