import React, { useEffect, useState, useRef, useCallback } from 'react';
import './App.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import Sidebar from "./components/Sidebar";
import TileLayerSwitcher from "./components/TileLayerSwitcher";
import Layers from "./components/Layers";
import { Typography } from "antd";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";

const isProduction = process.env.NODE_ENV === 'production';
const BASE_URL = isProduction ? 'https://somemvp.com/api' : 'http://127.0.0.1:8000/api';

interface Config {
    zoom: number;
    center: [number, number];
}

const GeoJsonMap: React.FC = () => {
    const [config, setConfig] = useState<Config>({
        zoom: 7,
        center: [56, 11],
    });

    const [colors, setColors] = useState<any[]>([]);
    const [thresholds, setThresholds] = useState<any[]>([]);
    const [selectedFeature, setSelectedFeature] = useState<string>('');
    const [selectedAmountOfColors, setSelectedAmountOfColors] = useState<number>(10);
    const [selectedOmraade, setSelectedOmraade] = useState<string>('');
    const [selectedLag, setSelectedLag] = useState<string>('');
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [visibleProps, setVisibleProps] = useState({ dim1: true, dim2: true, dim3: true });
    const [colorRange, setColorRange] = useState({ startColor: '#ff0000', middleColor: '#0000ff', endColor: '#00ff00' });
    const [headers, setHeaders] = useState<any[]>([]);
    const [defaults, setDefaults] = useState<any>({
        omraade: '',
        feature: '',
        lags: { lags: [], default: '' },
        keys: { keys: [], default: '' }
    });

    const handleSelect = useCallback((omraade: string, feature: string, lag: string, key: string) => {
        setSelectedOmraade(omraade);
        setSelectedFeature(feature);
        setSelectedLag(lag);
        setSelectedKey(key);
    }, []);

    const handleColorChange = useCallback((colors: { startColor: string; middleColor: string, endColor: string }) => {
        setColorRange(colors);
    }, []);

    const handleOpdelingChange = useCallback((opdeling: number) => {
        setSelectedAmountOfColors(opdeling);
    }, []);

    const fetchHeaders = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/headers`);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setHeaders(data);

            if (data && data.length > 0) {
                const firstOmraade = data[0];
                const firstFeature = firstOmraade.features?.[0];
                const firstLag = firstFeature?.lags?.[0];

                if (firstFeature && firstLag) {
                    const defaultValues = {
                        omraade: firstOmraade.omraade,
                        feature: firstFeature.feature,
                        lags: {
                            lags: firstFeature.lags.map((lag: any) => lag.lag),
                            default: 'auto'
                        },
                        keys: {
                            keys: firstLag.keys,
                            default: firstLag.keys[0]
                        }
                    };

                    setDefaults(defaultValues);
                    handleSelect(defaultValues.omraade, defaultValues.feature, defaultValues.lags.default, defaultValues.keys.default);
                }
            }

        } catch (error) {
            console.error('Error fetching headers:', error);
        }
    }, [handleSelect]);

    useEffect(() => {
        fetchHeaders();
    }, [fetchHeaders]);

    return (
        <div className="map-container">
            <MapContainer center={config.center} zoom={config.zoom} style={{ height: '100vh', width: '100vw' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <TileLayerSwitcher />
                <Layers
                    visibleProps={visibleProps}
                    selectedFeature={selectedFeature}
                    selectedOmraade={selectedOmraade}
                    selectedLag={selectedLag}
                    selectedKey={selectedKey}
                    colorRange={colorRange}
                    BASE_URL={BASE_URL}
                    defaults={defaults}
                    selectedAmountOfColors={selectedAmountOfColors}
                    setThresholds={setThresholds}
                    setColors={setColors}
                />
                <MapUpdater config={config} />
            </MapContainer>

            {!!headers.length && (
                <div>
                    <div className="floating-card-container">
                        <Sidebar
                            colors={colors}
                            thresholds={thresholds}
                            headers={headers}
                            defaults={defaults}
                            config={config}
                            onSelect={handleSelect}
                            handleCenterChange={(e: any) => setConfig({ ...config, center: e.target.value })}
                            handleZoomChange={(e: any) => setConfig({ ...config, zoom: Number(e.target.value) })}
                            handleColorChange={handleColorChange}
                            handleOpdelingChange={handleOpdelingChange}
                        />
                    </div>

                    {thresholds.length > 0 && (
                        <div className="legend">
                            <Title level={4} style={{ color: "rgba(0,0,0,0.8)", marginTop: 12 }}>Farve Intevaler</Title>
                            {thresholds.map((threshold, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: colors[index],
                                            marginRight: '8px',
                                        }}
                                    />
                                    <Paragraph style={{ color: "rgba(0,0,0,0.8)", margin: 0, fontWeight: 400 }}>
                                        {`${threshold.toFixed(2)} - ${thresholds[index + 1] ? thresholds[index + 1].toFixed(2) : 'Max'}`}
                                    </Paragraph>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const MapUpdater: React.FC<{ config: Config }> = ({ config }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(config.center, config.zoom);
    }, [config, map]);

    return null;
};

export default GeoJsonMap;
