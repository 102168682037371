import React, { useState, useMemo } from 'react';
import {Slider, Input, ColorPicker} from 'antd';
import debounce from 'lodash/debounce';
import Title from "antd/lib/typography/Title";

interface ColorRange {
    startColor: string;
    middleColor: string;
    endColor: string;
}

interface ColorRangeSelectorProps {
    parentHandleColorChange: (colorRange: ColorRange) => void;
}

const ColorRangeSelector: React.FC<ColorRangeSelectorProps> = ({ parentHandleColorChange }) => {

    const [colorRange, setColorRange] = useState<ColorRange>({
        startColor: '#ff0000', // Red
        middleColor: '#0055ff', // Red
        endColor: '#00ff00',   // Green
    });

    // Debounced version of the parentHandleColorChange
    const debouncedHandleColorChange = useMemo(() =>
        debounce((updatedColorRange: ColorRange) => {
            parentHandleColorChange(updatedColorRange);
        }, 300), [parentHandleColorChange]);  // 300ms debounce delay

    // Handler for color input change
    const handleColorChange = (field: keyof ColorRange, value: string) => {
        const updatedColorRange = {
            ...colorRange,
            [field]: value,
        };
        setColorRange(updatedColorRange);
        debouncedHandleColorChange(updatedColorRange);  // Call the debounced parent function
    };

    // Render gradient between start and end colors
    const gradientStyle = {
        background: `linear-gradient(to right, ${colorRange.startColor}, ${colorRange.middleColor}, ${colorRange.endColor})`,
        height: '20px',
        width: '100%',
        borderRadius: '5px',
    };

    return (
        <>
            <Title level={4} style={{ marginTop: 0 }}>Farveskala</Title>
            <div>
                <div style={ gradientStyle }></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0 0 0' }}>
                <ColorPicker
                    value={colorRange.startColor}
                    onChange={(color) => handleColorChange('startColor', color.toCssString())}
                />
                <ColorPicker
                    value={colorRange.middleColor}
                    onChange={(color) => handleColorChange('middleColor', color.toCssString())}
                />
                <ColorPicker
                    value={colorRange.endColor}
                    onChange={(color) => handleColorChange('endColor', color.toCssString())}
                />
            </div>
        </>
    );
};

export default ColorRangeSelector;
