import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { scaleLinear, scaleThreshold } from "d3-scale";
import { debounce } from "lodash";
import { interpolateHsl } from "d3-interpolate";

const Layers: React.FC<{
    visibleProps: any;
    selectedOmraade: string;
    selectedFeature: string;
    selectedLag: string;
    selectedKey: string;
    colorRange: { startColor: string; middleColor: string; endColor: string };
    selectedAmountOfColors: number;
    BASE_URL: string;
    defaults: any;
    setThresholds: any;
    setColors: any;
}> = ({
          visibleProps,
          selectedOmraade,
          selectedFeature,
          selectedLag,
          selectedKey,
          colorRange,
          selectedAmountOfColors,
          BASE_URL,
          defaults,
          setThresholds,
          setColors,
      }) => {

    const map = useMap();
    const geoJsonLayer = useRef<L.GeoJSON | null>(null);
    const [dataRange, setDataRange] = useState<[number, number] | null>(null); // State to store data range

    // Generate an array of color stops based on the selected number of colors
    const generateColorRange = useCallback(() => {
        const colorScale = scaleLinear<string>()
            .domain([0, 0.5, 1]) // Three points for start, middle, and end
            .range([colorRange.startColor, colorRange.middleColor, colorRange.endColor])
            .interpolate(interpolateHsl); // HSL interpolation for smoother transitions

        const generatedColors = Array.from({ length: selectedAmountOfColors }, (_, i) =>
            colorScale(i / (selectedAmountOfColors - 1))
        );

        setColors(generatedColors); // Save colors for use in the legend
        return generatedColors;
    }, [colorRange, selectedAmountOfColors, setColors]);

    const generateThresholds = useCallback(
        (minValue: number, maxValue: number) => {
            const step = (maxValue - minValue) / selectedAmountOfColors;
            const generatedThresholds = Array.from({ length: selectedAmountOfColors }, (_, i) =>
                minValue + step * i
            );

            setThresholds(generatedThresholds); // Save thresholds for the legend
            return generatedThresholds;
        },
        [selectedAmountOfColors, setThresholds]
    );

    const applyStyle = useCallback(
        (feature: any) => {
            const properties = feature.properties;
            const value = properties?.value ? parseFloat(properties.value) : 0;

            if (!dataRange) {
                return { fillColor: '#999999', weight: 1, opacity: 1, color: 'white', fillOpacity: 0.7 };
            }

            const [minValue, maxValue] = dataRange;
            const thresholds = generateThresholds(minValue, maxValue);
            const colors = generateColorRange();

            const colorScale = scaleThreshold<number, string>()
                .domain(thresholds.slice(1)) // Slice to match colors and thresholds
                .range(colors);

            const fillColor = colorScale(value) || '#999999';

            return {
                fillColor,
                weight: 1,
                opacity: 1,
                color: 'white',
                fillOpacity: 0.7,
            };
        },
        [generateColorRange, generateThresholds, dataRange, selectedAmountOfColors]
    );

    const calculateDataRange = useCallback((data: any) => {
        const values = data.features
            .map((feature: any) => parseFloat(feature.properties.value))
            .filter((value: number) => !isNaN(value));

        if (values.length > 0) {
            const min = Math.min(...values);
            const max = Math.max(...values);
            setDataRange([min, max]);
        }
    }, []);

    const updateLayerStyles = useCallback(() => {
        if (geoJsonLayer.current) {
            geoJsonLayer.current.eachLayer((layer) => {
                if (layer instanceof L.Polygon) {
                    const feature = (layer as any).feature;
                    layer.setStyle(applyStyle(feature));
                }
            });
        }
    }, [applyStyle]);

    // Memoized fetchGeoJsonData to avoid re-creation on every render
    const fetchGeoJsonData = useMemo(
        () =>
            debounce(async (lag?: any) => {
                console.log(lag, selectedFeature, selectedOmraade); // Logs updated values

                if (!lag || !!lag.type) {
                    lag = selectedLag;
                }

                if (selectedKey === '') return;

                const bounds = map.getBounds();
                const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()]
                    .map((coord) => coord.toFixed(6))
                    .join(',');

                const zoom = map.getZoom();
                let urlParams = `&omraade=${selectedOmraade}&feature=${selectedFeature}&key=${selectedKey}&zoom=${zoom}&bbox=${bbox}`;
                let url = `${BASE_URL}?lag=${lag}${urlParams}`;

                if (lag == 'auto') {
                    if (zoom <= 10) {
                        url = `${BASE_URL}?lag=kommune${urlParams}`;
                    } else if (zoom <= 14) {
                        url = `${BASE_URL}?lag=sogn${urlParams}`;
                    } else {
                        url = `${BASE_URL}?lag=merged${urlParams}`;
                    }
                }

                try {
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();

                    if (!data.features) {
                        throw new Error('Invalid data format');
                    }

                    calculateDataRange(data);

                    if (geoJsonLayer.current) {
                        geoJsonLayer.current.clearLayers();
                        geoJsonLayer.current.addData(data.features);
                    } else {
                        geoJsonLayer.current = L.geoJSON(data.features, {
                            style: applyStyle,
                        }).addTo(map);
                    }

                    updateLayerStyles();
                } catch (error) {
                    console.error('Error fetching GeoJSON data:', error);
                }
            }, 500),
        [
            map,
            selectedOmraade,   // Add selectedOmraade to the dependencies
            selectedFeature,    // Add selectedFeature to the dependencies
            selectedKey,
            selectedLag,
            visibleProps,
            applyStyle,
            updateLayerStyles,
            calculateDataRange,
        ]
    );

    useEffect(() => {
        fetchGeoJsonData(selectedLag);
    }, [selectedOmraade, selectedFeature, selectedLag, selectedKey]);

    useEffect(() => {
        updateLayerStyles();
    }, [colorRange, updateLayerStyles]);

    // Stable event handler attachment
    useEffect(() => {
        map.on('moveend', fetchGeoJsonData);
        map.on('zoomend', fetchGeoJsonData);
        return () => {
            map.off('moveend', fetchGeoJsonData);
            map.off('zoomend', fetchGeoJsonData);
        };
    }, [map, fetchGeoJsonData]);

    return null;
};

export default Layers;
