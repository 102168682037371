import React, {useRef, useState} from "react";
import {useMap} from "react-leaflet";
import L from "leaflet";
import {Button} from "antd";

const TileLayerSwitcher: React.FC = () => {
    const map = useMap();
    const currentLayerRef = useRef<L.TileLayer | null>(null); // Track the current tile layer

    const [activeTab, setActiveTab] = useState('street');

    const changeTileLayer = (url: string, type: string) => {
        // Remove the previous tile layer if it exists
        if (currentLayerRef.current) {
            map.removeLayer(currentLayerRef.current);
        }

        // Add the new tile layer
        const newLayer = new L.TileLayer(url);
        newLayer.addTo(map);
        currentLayerRef.current = newLayer; // Update the current tile layer reference

        setActiveTab(type)
    };

    return (
        <div className={'tile-layer-buttons'}>
            <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <Button
                        type={activeTab === 'street' ? 'default' : 'text'}
                        style={{ flex: 1 }}
                        onClick={() => changeTileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', 'street')}
                    >
                        OpenStreetMap
                    </Button>
                    <Button
                        type={activeTab === 'dark' ? 'default' : 'text'}
                        style={{ flex: 1 }}
                        onClick={() => changeTileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', 'dark')}
                    >
                        Dark Mode
                    </Button>
                    <Button
                        type={activeTab === 'topo' ? 'default' : 'text'}
                        style={{ flex: 1 }}
                        onClick={() => changeTileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', 'topo')}
                    >
                        Topographic Map
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TileLayerSwitcher;